import React, { useState, useCallback } from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';

import { sendContact } from '../services/contact.service';
import { Input } from './input.component';
import { FormContext } from '../utils/form.context';
import { FormControl } from './form-control.component';
import { Textarea } from './textarea.component';
import { Button } from './button.component';

import './contact-form.component.scss';

export const ContactForm = () => {
    const { t, navigate } = useI18next();
    const [ isSending, setIsSending ] = useState(false);
    const [ isSent, setIsSent ] = useState(false);
    const [ errors, setErrors ] = useState(null);

    const [formValues, setFormValues] = useState({
        lastName: '',
        firstName: '',
        lastNameJa: '',
        firstNameJa: '',
        phoneNumber: '',
        emailAddress: '',
        inquiryContent: ''
    });
    const handleContactSubtmit = (e) => {
        e.preventDefault();
        setIsSending(true);

        sendContact(formValues)
            .then(res => {
                console.log(res.data);
                setIsSending(false);
                setIsSent(true);
            })
            .catch(error => {
                setErrors(error.response.data.message)
                console.error('send contact ', error);
            });
    }

    const handleOnChange = useCallback(e => {
        setErrors(null);
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
    }, [ formValues, setFormValues ]);

    

    return (
        <div className="contact-form">
            {!isSent ?
                <FormContext.Provider value={errors}>
                    <form onSubmit={handleContactSubtmit} className="flex-form">
                        <FormControl label={`${t('Full Name')}`} className="half-width" name="lastName" largeLabel={true}>
                            <Input name="lastName" placeholder={t("姓")}  value={formValues.lastName||''} onChange={handleOnChange} />
                        </FormControl>
                        <FormControl className="half-width" name="firstName" largeLabel={true}>
                            <Input name="firstName" placeholder="名" value={formValues.firstName||''} onChange={handleOnChange} />
                        </FormControl>
                        <FormControl label={`${t('Furigana')}`} className="half-width" name="lastNameJa" largeLabel={true}>
                            <Input name="lastNameJa" placeholder="セイ"  value={formValues.lastNameJa||''} onChange={handleOnChange} />
                        </FormControl>
                        <FormControl className="half-width" name="firstNameJa" largeLabel={true}>
                            <Input name="firstNameJa" placeholder="メイ" value={formValues.firstNameJa||''} onChange={handleOnChange} />
                        </FormControl>
                        <FormControl label={`${t('Email')}`} name="emailAddress" largeLabel={true}>
                            <Input name="emailAddress" value={formValues.emailAddress} onChange={handleOnChange} />
                        </FormControl>
                        <FormControl label={`${t('Phone Number')}`} name="phoneNumber" largeLabel={true}>
                            <Input name="phoneNumber" value={formValues.phoneNumber} onChange={handleOnChange} />
                        </FormControl>
                        <FormControl label={`${t('Inquiry content')}`} name="inquiryContent" largeLabel={true}>
                            <Textarea name="inquiryContent" value={formValues.inquiryContent} onChange={handleOnChange} />
                        </FormControl>
                        <Button className="contact-form-submit-button" type="submit">送信する</Button>
                    </form>
                </FormContext.Provider>
                :
                <div className="sent-confirm">
                    <h3 className="t-heading2 t-serif t-bold">{t('Thanks for contacting us!')}</h3>
                    <p className="t-sans t-bold t-grey9">この度は、お問い合わせをいただきまして誠にありがとうございます。</p>
                    <p className="t-sans t-bold t-grey9">内容を確認させていただきまして担当者よりご連絡させていただきます。</p>
                    <p className="t-sans t-bold t-grey9">数日経過しても折り返しの連絡がない場合は、お手数ですが、再度お問い合わせいただくか、お電話にて0120-294-998までご連絡いただけましたら幸いです。</p>
                </div>
            }
        </div>
    )
}

